import { createContext, useState } from "react";

export const EmpresaContext = createContext({
    currentEmpresa: [],
    setCurrentEmpresa: () => null,
});

export const EmpresaProvider = ({ children }) => {
    const [currentEmpresa, setCurrentEmpresa] = useState([]);

    const value = { currentEmpresa, setCurrentEmpresa };

    return <EmpresaContext.Provider value={value}>{children}</EmpresaContext.Provider>;
}