import { createContext, useState } from "react";

export const EcraContext = createContext({
    currentEcra: [],
    setCurrentEcra: () => null,
});

export const EcraProvider = ({ children }) => {
    const [currentEcra, setCurrentEcra] = useState([]);

    const value = { currentEcra, setCurrentEcra };

    return <EcraContext.Provider value={value}>{children}</EcraContext.Provider>;
}