import { useContext, useEffect, useState } from "react";

import Header from "../header/header.component";
import Loader from "../loader/loader.component";
import "./home.styles.scss";
import { getEmpresaByEmail } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import Main from "../main/main.component";
import { EmpresaContext } from "../../contexts/empresa.context";

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);

    const { currentUser } = useContext(UserContext);
    const { currentEmpresa, setCurrentEmpresa } = useContext(EmpresaContext);

    useEffect(() => {
        const fetchEmpresaInfo = async () => {
            setIsLoading(true);
            const info = await getEmpresaByEmail(currentUser.email);
            let infoArr = [];

            info.forEach(doc => {
                infoArr.push(doc.data());
            })

            setCurrentEmpresa(infoArr);
            setIsLoading(false);
        }

        fetchEmpresaInfo();
    }, [currentUser, setCurrentEmpresa])

    return (
        <div className="home_container">
            {
                isLoading
                    ?
                        <Loader />
                    :
                        (
                            currentEmpresa.length > 0 &&
                                <>
                                    <Header nomeEmpresa={currentEmpresa[0].nome} />
                                    <Main />
                                </>

                        )

            }
        </div>
    );
}

export default Home;