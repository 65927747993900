import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./header.styles.scss";

import { signOutWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";
import { EcrasContext } from "../../contexts/ecras.context";
import { EmpresaContext } from "../../contexts/empresa.context";

const Header = ({ nomeEmpresa }) => {
    const { setCurrentUser } = useContext(UserContext);
    const { setCurrentEmpresa } = useContext(EmpresaContext);
    const { setEcrasList } = useContext(EcrasContext);

    const navigate = useNavigate();
    
    const handleSignOut = () => {
        signOutWithEmailAndPassword();
        setCurrentUser(null);
        setCurrentEmpresa([]);
        setEcrasList([]);
        navigate("/");
    }

    return (
        <div className="header_container">
            <h2>{nomeEmpresa}</h2>
            <span className="sair_btn" onClick={handleSignOut}>Sair</span>
        </div>
    );
}

export default Header;