import { createContext, useState } from "react";

export const EcrasContext = createContext({
    ecrasList: [],
    setEcrasList: () => null,
});

export const EcrasProvider = ({ children }) => {
    const [ecrasList, setEcrasList] = useState([]);

    const value = { ecrasList, setEcrasList };

    return <EcrasContext.Provider value={value}>{children}</EcrasContext.Provider>;
}