import { useContext } from 'react';

import Login from './components/login/login.component';
import { UserContext } from './contexts/user.context';
import Home from './components/home/home.component';
import Loader from './components/loader/loader.component';

function App() {
  const { currentUser, isLoading } = useContext(UserContext);

  return (
    <div className='app'>
      {
        isLoading
          ?
            <Loader />
          :
            (
              currentUser
                ? <Home />
                : <Login />
            )

      }
    </div>
  );
}

export default App;
