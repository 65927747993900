import { useContext, useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import { Modal } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { useParams } from "react-router-dom";

import "./conteudos_list.styles.scss";
import { deleteConteudo, getEcraByID, addConteudosToEcra, updateOrdemConteudo } from "../../utils/firebase/firebase.utils";
import { ConteudosContext } from "../../contexts/conteudos.context";
import Input from "../input/input.component";

const ConteudosList = () => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [nomeConteudo, setNomeConteudo] = useState("");
    const [ordemValue, setOrdemValue] = useState(0);

    const { conteudosList, setConteudosList, sortedConteudos, setSortedConteudos  } = useContext(ConteudosContext);

    const { uniqueId } = useParams();

    const handleDeleteModalToggle = (nomeConteudo) => {
        setNomeConteudo(nomeConteudo);
        setIsOpenDeleteModal(!isOpenDeleteModal);
    }

    const handleEditModalToggle = (nomeConteudo) => {
        setNomeConteudo(nomeConteudo);
        setIsOpenEditModal(!isOpenEditModal);
    }

    const handleOrdemChange = (evt) => {
        setOrdemValue(evt.target.value);
    }

    const handleDeleteSubmit = async () => {
        setIsSending(true);

        await deleteConteudo(nomeConteudo, uniqueId);

        const newConteudosList = conteudosList.filter(conteudo => conteudo.fileName !== nomeConteudo);

        setConteudosList([...newConteudosList], async (currentConteudosList) => {
            await addConteudosToEcra(uniqueId, currentConteudosList)
            const conteudosArr = currentConteudosList;
            conteudosArr.sort((a, b) => a.ordem - b.ordem);
    
            setSortedConteudos(conteudosArr);
        });


        setIsSending(false);
        setIsOpenDeleteModal(false);
    }

    const handleEditSubmit = async (uniqueId, nomeConteudo) => {
        if (!ordemValue) {
            alert("Escreva algo na caixa de texto.");
            return;
        }

        setIsSending(true);

        const filteredArr = conteudosList.filter(conteudo => conteudo.fileName !== nomeConteudo);
        const updatedArr = conteudosList.filter(conteudo => conteudo.fileName === nomeConteudo);
        updatedArr[0].ordem = +ordemValue;

        setConteudosList([...filteredArr, ...updatedArr], async (currentConteudosList) => {
            const conteudosArr = currentConteudosList;
            conteudosArr.sort((a, b) => a.ordem - b.ordem);

            await updateOrdemConteudo(uniqueId, conteudosArr);

            setSortedConteudos(conteudosArr);
        });

        

        setIsSending(false);
        setOrdemValue("");
        setIsOpenEditModal(false);
    }

    useEffect(() => {
        const sortConteudos = (conteudosArr) => {
            setSortedConteudos(conteudosArr);
        }

        const fetchConteudosInfo = async () => {
            const conteudosInfo = await getEcraByID(uniqueId);
            let conteudosArr = [];

            conteudosInfo.forEach(doc => {
                if (doc.data()["conteudos"]) {
                    conteudosArr.push(...doc.data()["conteudos"]);
                }
            })

            conteudosArr.sort((a, b) => a.ordem - b.ordem);

            setConteudosList(conteudosArr);
            sortConteudos(conteudosArr);
        }

        fetchConteudosInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="conteudos_list_container">
            {
                sortedConteudos.map((conteudo, index) => (
                    (
                        conteudo.contentType === "image"
                            ?
                                <div className="main_div" key={index}>
                                    <img className="conteudo_main" src={conteudo.url} alt="Conteudo" />
                                    <EditIcon className="edit_icon" onClick={() => handleEditModalToggle(conteudo.fileName)} />
                                    <DeleteIcon className="delete_icon" onClick={() => handleDeleteModalToggle(conteudo.fileName)} />
                                </div>
                            :
                                <div className="main_div" key={index}>
                                    <video className="conteudo_main" controls>
                                        <source src={conteudo.url} />
                                        O seu browser não suporta a Tag de vídeo
                                    </video>
                                    <EditIcon className="edit_icon" onClick={() => handleEditModalToggle(conteudo.fileName)} />
                                    <DeleteIcon className="delete_icon" onClick={() => handleDeleteModalToggle(conteudo.fileName)} />
                                </div>
                    )
                ))
            }
            <Modal
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                open={isOpenDeleteModal}
                onClose={handleDeleteModalToggle}
                aria-labelledby="Eliminar Conteúdo"
                aria-describedby="Modal que permite eliminar conteúdo"
            >
                <div className="modal_content_container">
                    {
                        isSending
                            ?
                                <ThreeCircles 
                                    color="#000"
                                    height="50"
                                    width="50"
                                    wrapperStyle={{
                                        display: "flex", 
                                        justifyContent: "center", 
                                        alignItems: "center" 
                                    }}
                                />
                            :
                                <>
                                    <span>Tem a certeza que pretende eliminar este conteúdo?</span>
                                    <button className="form_btn" onClick={() => handleDeleteSubmit(uniqueId)}>Eliminar</button>
                                </>
                    }
                </div>
            </Modal>
            <Modal
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                open={isOpenEditModal}
                onClose={handleEditModalToggle}
                aria-labelledby="Alterar Ordem do conteúdo"
                aria-describedby="Modal que permite alterar ordem do conteúdo"
            >
                <div className="modal_content_container">
                    {
                        isSending
                            ?
                                <ThreeCircles 
                                    color="#000"
                                    height="50"
                                    width="50"
                                    wrapperStyle={{
                                        display: "flex", 
                                        justifyContent: "center", 
                                        alignItems: "center" 
                                    }}
                                />
                            :
                                <>
                                    <span>Insira a ordem que pretende para este conteúdo.</span>
                                    <Input
                                        type="number"
                                        onChange={handleOrdemChange}
                                    />
                                    <button className="form_btn" onClick={() => handleEditSubmit(uniqueId, nomeConteudo)}>Submeter</button>
                                </>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default ConteudosList;