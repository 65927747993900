import { ThreeCircles } from "react-loader-spinner";

const Loader = () => {
    return (
        <ThreeCircles 
            color="#000"
            height="150"
            width="150"
            wrapperStyle={{ 
                height: "100vh", 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center" 
            }}
        />
    )
}

export default Loader;