import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import "./login.styles.scss";

import Input from "../input/input.component";
import { loginWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";

const Login = () => {
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const { setCurrentUser } = useContext(UserContext);

    const handleEmailChange = (evt) => {
        setEmailValue(evt.target.value);
    }

    const handlePasswordChange = (evt) => {
        setPasswordValue(evt.target.value);
    }

    const handleSubmit = async () => {
        const user = await loginWithEmailAndPassword(emailValue, passwordValue);

        if (user) {
            setCurrentUser(user.user);
        }
    }

    return (
        <div className="login_wrapper">
            <div className="login_container">
                <h2>Gestão de Signage</h2>
                <div className="form_container">
                    <Input
                        type="email"
                        placeholder="Email da Empresa"
                        value={emailValue}
                        onChange={handleEmailChange}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={passwordValue}
                        onChange={handlePasswordChange}
                    />
                    <button className="form_btn" onClick={handleSubmit}>Login</button>
                </div>
            </div>
            <span className="span_text">Não tens conta? Regista-te <Link className="span_link" to="sign_up">aqui!</Link></span>
        </div>
    )
}

export default Login;