import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./sign_up.styles.scss";

import Input from "../input/input.component";
import { signUpWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { UserContext } from "../../contexts/user.context";

const SignUp = () => {
    const [nomeValue, setNomeValue] = useState("");
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");

    const { setCurrentUser } = useContext(UserContext);

    const navigate = useNavigate();

    const handleNomeChange = (evt) => {
        setNomeValue(evt.target.value);
    }

    const handleEmailChange = (evt) => {
        setEmailValue(evt.target.value);
    }

    const handlePasswordChange = (evt) => {
        setPasswordValue(evt.target.value);
    }

    const handleSubmit = async () => {
        const user = await signUpWithEmailAndPassword(nomeValue, emailValue, passwordValue);

        if (user) {
            setCurrentUser(user.user);
            navigate("/");
        }
    }

    return (
        <div className="sign_up_wrapper">
            <div className="sign_up_container">
                <h2>Gestão de Signage</h2>
                <div className="form_container">
                    <Input
                        type="text"
                        placeholder="Nome da Empresa"
                        value={nomeValue}
                        onChange={handleNomeChange}
                    />
                    <Input
                        type="email"
                        placeholder="Email da Empresa"
                        value={emailValue}
                        onChange={handleEmailChange}
                    />
                    <Input
                        type="password"
                        placeholder="Password"
                        value={passwordValue}
                        onChange={handlePasswordChange}
                    />
                    <button className="form_btn" onClick={handleSubmit}>Registar</button>
                </div>
            </div>
            <span className="span_text">Já tens uma conta? Faz login <Link className="span_link" to="/">aqui!</Link></span>
        </div>
    )
}

export default SignUp;