import { createContext, useState, useEffect } from "react";
import { onAuthStateChangedListener } from "../utils/firebase/firebase.utils";

export const UserContext = createContext({
    currentUser: null,
    setCurrentUser: () => null,
    isLoading: true,
    setIsLoading: () => null,
});

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const value = { currentUser, setCurrentUser, isLoading, setIsLoading };

    useEffect(() => {
        const unsubscribe = onAuthStateChangedListener(setCurrentUser, setIsLoading);

        return () => {
            unsubscribe();
        };
    }, []);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}