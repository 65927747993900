import { useState,createContext } from "react";
import { useStateWithCallbackLazy } from "use-state-with-callback";

export const ConteudosContext = createContext({
    conteudosList: [],
    setConteudosList: () => null,
    sortedConteudos: [],
    setSortedConteudos: () => null,
});

export const ConteudosProvider = ({ children }) => {
    const [conteudosList, setConteudosList] = useStateWithCallbackLazy([]);
    const [sortedConteudos, setSortedConteudos] = useState([]);

    const value = { conteudosList, setConteudosList, sortedConteudos, setSortedConteudos };

    return <ConteudosContext.Provider value={value}>{children}</ConteudosContext.Provider>;
}