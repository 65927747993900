import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { FileUploader } from "react-drag-drop-files";

import "./conteudos.styles.scss";
import Header from "../header/header.component";
import Loader from "../loader/loader.component";
import { UserContext } from "../../contexts/user.context";
import { EmpresaContext } from "../../contexts/empresa.context";
import { ConteudosContext } from "../../contexts/conteudos.context";
import { EcraContext } from "../../contexts/ecra.context";
import { addConteudosToEcra, addFile, getEcraByID, getEmpresaByEmail } from "../../utils/firebase/firebase.utils";
import ConteudosList from "../conteudos_list/conteudos_list.component";

const Conteudos = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [files, setFiles] = useState([]);

    const { currentUser } = useContext(UserContext);
    const { currentEmpresa, setCurrentEmpresa } = useContext(EmpresaContext);
    const { currentEcra, setCurrentEcra } = useContext(EcraContext);
    const { conteudosList, setConteudosList, setSortedConteudos } = useContext(ConteudosContext);

    const { uniqueId } = useParams();
    const navigate = useNavigate();

    const handleModalToggle = () => {
        setFiles([]);
        setShowModal(!showModal);
    }

    const handleFileChange = (files) => {
        setFiles(files);
    }

    const handleSubmit = async () => {
        if (!files) {
            alert("Insira um ficheiro.");
            return;
        }

        setIsSending(true);
        let newConteudoList = [];

        for (let i = 0; i < files.length; i++) {
            const conteudoObj = await addFile(uniqueId, files[i]);

            newConteudoList.push(conteudoObj);
        }

        setConteudosList([...conteudosList, ...newConteudoList], async (currentConteudosList) => {
            await addConteudosToEcra(uniqueId, currentConteudosList);

            const conteudosArr = currentConteudosList;
            conteudosArr.sort((a, b) => a.ordem - b.ordem);

            setSortedConteudos(conteudosArr);
        });

        setIsSending(false);
        setShowModal(false);
    }
    
    useEffect(() => {
        const fetchInfo = async () => {
            if (!currentUser) {
                navigate("/");
                return;
            }

            setIsLoading(true);
            const infoEmpresa = await getEmpresaByEmail(currentUser.email);
            const infoEcra = await getEcraByID(uniqueId);
            let infoEmpresaArr = [];
            let infoEcraArr = [];

            infoEmpresa.forEach(doc => {
                infoEmpresaArr.push(doc.data());
            })

            infoEcra.forEach(doc => {
                infoEcraArr.push(doc.data());
            })

            setCurrentEmpresa(infoEmpresaArr);
            setCurrentEcra(infoEcraArr);
            setIsLoading(false);
        }

        fetchInfo();
    }, [currentUser, setCurrentEmpresa, uniqueId, setCurrentEcra, navigate])

    return (
        <>
            {
                isLoading
                    ?
                        <Loader />
                    :
                        (
                            currentEmpresa.length > 0 &&
                                <>
                                    <Header nomeEmpresa={currentEmpresa[0].nome} />
                                    <div className="main_content">
                                        {
                                            currentEcra.length > 0 &&
                                                <>
                                                    <h3>{currentEcra[0].nomeEcra}</h3>
                                                    <span 
                                                        style={{ userSelect: "none" }} 
                                                        className="add_span"
                                                        onClick={handleModalToggle}
                                                    >
                                                        + Adicionar Conteúdo
                                                    </span>
                                                    <ConteudosList />
                                                </>
                                        }
                                    </div>
                                    <Modal
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        open={showModal}
                                        onClose={handleModalToggle}
                                        aria-labelledby="Adicionar Conteúdo"
                                        aria-describedby="Modal que permite adicionar um conteúdo"
                                    >
                                        <div className="modal_content_container">
                                            {
                                                isSending
                                                    ?
                                                        <ThreeCircles 
                                                            color="#000"
                                                            height="50"
                                                            width="50"
                                                            wrapperStyle={{
                                                                display: "flex", 
                                                                justifyContent: "center", 
                                                                alignItems: "center" 
                                                            }}
                                                        />
                                                    :
                                                        <>
                                                            <span>Adicione um conteúdo ao seu ecrã</span>
                                                            <FileUploader
                                                                children={<FileUploaderContent/>}
                                                                name="files" 
                                                                multiple={true} 
                                                                handleChange={handleFileChange} 
                                                                hoverTitle="a"
                                                            />
                                                            {
                                                                files.length > 0 &&
                                                                    <span>Fez upload de {files.length} ficheiro(s)</span>
                                                            }
                                                            <button className="form_btn" onClick={handleSubmit}>Adicionar Conteúdo</button>
                                                        </>
                                            }
                                        </div>
                                    </Modal>
                                </>
                        )
            }
        </>
    )
}

const FileUploaderContent = () => {
    return (
        <div className="uploader_container">
            <span className="uploader_text">Faça upload ou arraste para aqui o(s) seu(s) ficheiro(s)</span>
        </div>
    )
}

export default Conteudos;