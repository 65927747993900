import { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { nanoid } from "nanoid";
import { ThreeCircles } from "react-loader-spinner";

import "./main.styles.scss";
import Input from "../input/input.component";
import EcrasList from "../ecras_list/ecras_list.component";
import { EmpresaContext } from "../../contexts/empresa.context";
import { EcrasContext } from "../../contexts/ecras.context";
import { addEcraToEmpresa } from "../../utils/firebase/firebase.utils";

const Main = () => {
    const [showModal, setShowModal] = useState(false);
    const [nomeEcraValue, setNomeEcraValue] = useState("");
    const [isSending, setIsSending] = useState(false);

    const { currentEmpresa } = useContext(EmpresaContext);
    const { setEcrasList, ecrasList } = useContext(EcrasContext);

    const handleNomeEcraChange = (evt) => {
        setNomeEcraValue(evt.target.value);
    }

    const handleModalToggle = () => {
        setShowModal(!showModal);
    }

    const handleSubmit = async () => {
        setIsSending(true);
        const uniqueId = nanoid(6);

        await addEcraToEmpresa(uniqueId, nomeEcraValue, currentEmpresa[0].nome);

        setEcrasList([...ecrasList, {
            uniqueId: uniqueId,
            nomeEcra: nomeEcraValue,
            nomeEmpresa: currentEmpresa[0].nome
        }]);
        setIsSending(false);
        setShowModal(false);
    }

    return (
        <div className="main_content">
            <h3>Ecrãs</h3>
            <span 
                style={{ userSelect: "none" }} 
                className="add_span" 
                onClick={handleModalToggle}
            >
                + Adicionar Ecrã
            </span>
            <Modal
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                open={showModal}
                onClose={handleModalToggle}
                aria-labelledby="Adicionar Ecrã"
                aria-describedby="Modal que permite adicionar um ecrã"
            >
                <div className="modal_content_container">
                    {
                        isSending
                            ?
                                <ThreeCircles 
                                    color="#000"
                                    height="50"
                                    width="50"
                                    wrapperStyle={{
                                        display: "flex", 
                                        justifyContent: "center", 
                                        alignItems: "center" 
                                    }}
                                />
                            :
                                <>
                                    <span>Adicione um ecrã à sua empresa</span>
                                    <Input
                                        type="text"
                                        placeholder="Nome do Ecrã"
                                        value={nomeEcraValue}
                                        onChange={handleNomeEcraChange}
                                    />
                                    <button className="form_btn" onClick={handleSubmit}>Adicionar Ecrã</button>
                                </>
                    }
                </div>
            </Modal>
            <EcrasList />
        </div>
    )
}

export default Main;