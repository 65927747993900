import { useContext, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { Modal } from "@mui/material";
import { ThreeCircles } from "react-loader-spinner";
import { Link } from "react-router-dom";

import "./ecras_list.styles.scss";
import { deleteEcra, getEcrasByNomeEmpresa, updateNomeEcra } from "../../utils/firebase/firebase.utils";
import { EcrasContext } from "../../contexts/ecras.context";
import { EmpresaContext } from "../../contexts/empresa.context";
import Input from "../input/input.component";

const EcrasList = () => {
    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [nomeEcraValue, setNomeEcraValue] = useState("");
    const [uniqueId, setUniqueId] = useState("");

    const { ecrasList, setEcrasList } = useContext(EcrasContext);
    const { currentEmpresa } = useContext(EmpresaContext);

    const handleEditModalToggle = (uniqueId) => {
        setUniqueId(uniqueId);
        setIsOpenEditModal(!isOpenEditModal);
    }

    const handleDeleteModalToggle = (uniqueId) => {
        setUniqueId(uniqueId);
        setIsOpenDeleteModal(!isOpenDeleteModal);
    }

    const handleNomeEcraChange = (evt) => {
        setNomeEcraValue(evt.target.value);
    }

    const handleEditSubmit = async (uniqueId, nomeEcra = nomeEcraValue) => {
        if (!nomeEcraValue) {
            alert("Escreva algo na caixa de texto.");
            return;
        }

        setIsSending(true);

        await updateNomeEcra(uniqueId, nomeEcra);

        const filteredArr = ecrasList.filter(ecra => ecra.uniqueId !== uniqueId);

        setEcrasList([...filteredArr, {
            uniqueId: uniqueId,
            nomeEcra: nomeEcra,
            nomeEmpresa: currentEmpresa[0].nome
        }])

        setIsSending(false);
        setNomeEcraValue("");
        setIsOpenEditModal(false);
    }

    const handleDeleteSubmit = async (uniqueId) => {
        setIsSending(true);

        await deleteEcra(uniqueId);

        const filteredArr = ecrasList.filter(ecra => ecra.uniqueId !== uniqueId);

        setEcrasList([...filteredArr]);

        setIsSending(false);
        setIsOpenDeleteModal(false);
    }

    useEffect(() => {
        const fetchEcrasInfo = async () => {
            const info = await getEcrasByNomeEmpresa(currentEmpresa[0].nome);
            let ecrasArr = [];

            info.forEach(doc => {
                ecrasArr.push(doc.data());
            })

            setEcrasList(ecrasArr);
        }

        fetchEcrasInfo();
    }, [currentEmpresa, setEcrasList]);

    return (
        <div className="ecras_list_container">
            {
                ecrasList.map(ecra => (
                    <div key={ecra.uniqueId} className="ecra_container">
                        <span className="ecra_title">{ecra.nomeEcra}</span>
                        <span><strong>ID:</strong> {ecra.uniqueId}</span>
                        <div className="actions_container">
                            <EditIcon className="edit_icon" onClick={() => handleEditModalToggle(ecra.uniqueId)} />
                            <DeleteIcon className="delete_icon" onClick={() => handleDeleteModalToggle(ecra.uniqueId)} />
                        </div>
                        <Link className="span_link" to={`conteudos/${ecra.uniqueId}`}>Ver Conteúdos</Link>
                    </div>
                ))
            }
            <Modal
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                open={isOpenEditModal}
                onClose={handleEditModalToggle}
                aria-labelledby="Alterar Nome do Ecrã"
                aria-describedby="Modal que permite alterar nome do ecrã"
            >
                <div className="modal_content_container">
                    {
                        isSending
                            ?
                                <ThreeCircles 
                                    color="#000"
                                    height="50"
                                    width="50"
                                    wrapperStyle={{
                                        display: "flex", 
                                        justifyContent: "center", 
                                        alignItems: "center" 
                                    }}
                                />
                            :
                                <>
                                    <span>Altere o nome do Ecrã</span>
                                    <Input
                                        type="text"
                                        placeholder="Nome do Ecrã"
                                        value={nomeEcraValue}
                                        onChange={handleNomeEcraChange}
                                    />
                                    <button className="form_btn" onClick={() => handleEditSubmit(uniqueId)}>Alterar Nome</button>
                                </>
                    }
                </div>
            </Modal>
            <Modal
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                open={isOpenDeleteModal}
                onClose={handleDeleteModalToggle}
                aria-labelledby="Eliminar Ecrã"
                aria-describedby="Modal que permite eliminar ecrã"
            >
                <div className="modal_content_container">
                    {
                        isSending
                            ?
                                <ThreeCircles 
                                    color="#000"
                                    height="50"
                                    width="50"
                                    wrapperStyle={{
                                        display: "flex", 
                                        justifyContent: "center", 
                                        alignItems: "center" 
                                    }}
                                />
                            :
                                <>
                                    <span>Tem a certeza que pretende eliminar este ecrã?</span>
                                    <button className="form_btn" onClick={() => handleDeleteSubmit(uniqueId)}>Eliminar</button>
                                </>
                    }
                </div>
            </Modal>
        </div>
    )
}

export default EcrasList;