// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { 
  getFirestore, 
  doc,
  setDoc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  deleteDoc
} from "firebase/firestore";

import { 
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  getMetadata,
  deleteObject
} from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCA71kZCqpPXBxJmOcdKBC7dow9EKEmbqc",
  authDomain: "albinet-signage.firebaseapp.com",
  projectId: "albinet-signage",
  storageBucket: "albinet-signage.appspot.com",
  messagingSenderId: "694164750441",
  appId: "1:694164750441:web:205e1dc241665b5c1c62f8",
  measurementId: "G-66847EDDP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth();
const storage = getStorage();

export const signUpWithEmailAndPassword = async (nomeEmpresa, emailEmpresa, password) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, emailEmpresa, password);

    if (user) {
      const empresaRef = doc(db, "empresas", nomeEmpresa);
      setDoc(empresaRef, {
        nome: nomeEmpresa,
        email: emailEmpresa
      });

      return user;
    }
  } catch (err) {
    if (err.code === "auth/email-already-in-use") {
      alert("Ocorreu um erro com o seu registo, tente novamente.");
    }
  }
}

export const loginWithEmailAndPassword = async (emailEmpresa, password) => {
  try {
    const user = await signInWithEmailAndPassword(auth, emailEmpresa, password);

    if (user) {
      return user;
    }
  } catch(err) {
    if (err.code === "auth/invalid-credential") {
      alert("Houve um erro com o seu login, tente novamente.");
    }
  }
}

export const onAuthStateChangedListener = (callback, loadingCallback) => {
  return onAuthStateChanged(auth, (user) => {
    loadingCallback(false);
    if (user) {
      callback(user);
    }
  });
}

export const signOutWithEmailAndPassword = () => {
  signOut(auth);
}

export const getEmpresaByEmail = async (emailEmpresa) => {
  const q = query(collection(db, "empresas"), where("email", "==", emailEmpresa));
  const querySnapshot = await getDocs(q);

  return querySnapshot;
}

export const getEcraByID = async (uniqueId) => {
  const q = query(collection(db, "ecras"), where("uniqueId", "==", uniqueId));
  const querySnapshot = await getDocs(q);

  return querySnapshot;
}

export const addEcraToEmpresa = async (uniqueId, nomeEcra, nomeEmpresa) => {
  await setDoc(doc(db, "ecras", uniqueId), {
    uniqueId: uniqueId,
    nomeEcra: nomeEcra,
    nomeEmpresa: nomeEmpresa
  });
}

export const addConteudosToEcra = async (uniqueId, conteudosList) => {
  await setDoc(doc(db, "ecras", uniqueId), {
    conteudos: conteudosList
  }, { merge: true });
}

export const updateNomeEcra = async (uniqueId, nomeEcra) => {
  const ecraRef = doc(db, "ecras", uniqueId);

  await updateDoc(ecraRef, {
    nomeEcra: nomeEcra
  });
}

export const updateOrdemConteudo = async (uniqueId, conteudosList) => {
  const ecraRef = doc(db, "ecras", uniqueId);

  await updateDoc(ecraRef, {
    conteudos: conteudosList
  });
}

export const deleteEcra = async (uniqueId) => {
  await deleteDoc(doc(db, "ecras", uniqueId));
}

export const getEcrasByNomeEmpresa = async (nomeEmpresa) => {
  const q = query(collection(db, "ecras"), where("nomeEmpresa", "==", nomeEmpresa));
  const querySnapshot = await getDocs(q);

  return querySnapshot;
}

export const getConteudosByID = async (uniqueId) => {
  const idRef = ref(storage, `${uniqueId}`);
  const result = await listAll(idRef);
  let conteudoList = [];

  for (let i = 0; i < result.items.length; i++) {
    let url = await getDownloadURL(result.items[i]);
    const metadata = await getMetadata(result.items[i]);

    let contentTypeString = metadata.contentType.split("/");

    const conteudoObj = {
      fileName: metadata.name,
      url: url,
      contentType: contentTypeString[0]
    }

    conteudoList.push(conteudoObj);
  }

  return conteudoList;
}

export const addFile = async (uniqueId, file) => {
  const pathIDRef = ref(storage, `${uniqueId}/${file.name}`);

  const fileSnapshot = await uploadBytes(pathIDRef, file);
  const url = await getDownloadURL(fileSnapshot.ref);
  const metadata = await getMetadata(fileSnapshot.ref);

  let contentTypeString = metadata.contentType.split("/");

  const conteudoObj = {
    fileName: metadata.name,
    url: url,
    contentType: contentTypeString[0],
    ordem: 0
  }

  return conteudoObj;
}

export const deleteConteudo = async (nomeConteudo, uniqueId) => {
  const conteudoRef = ref(storage, `${uniqueId}/${nomeConteudo}`);

  await deleteObject(conteudoRef);
}