import "./input.styles.scss";

const Input = ({ type, placeholder, value, onChange }) => {
    return (
        <div>
            <input
                className="input_container"
                type={type} 
                placeholder={placeholder}
                value={value}
                onChange={onChange} 
            />
        </div>
    )
}

export default Input;